





















































































































































































































































import Vue from 'vue';
import SafetyAnchorPointResourceViewModel from '@/src/services/viewModel/resource/SafetyAnchorPointResourceViewModel';
import SafetyHookResourceViewModel from '@/src/services/viewModel/resource/SafetyHookResourceViewModel';
import SiHaDocumentationResourceViewModel from '@/src/services/viewModel/resource/SiHaDocumentationResourceViewModel';

export default Vue.extend({
  auth: true,
  layout: 'layout-restricted-mobile-view',
  data: () => ({
    imagesLoading: false,
    safetyAnchorPointImageFiles: {} as Record<string, File[]>,
    siHaDocumentationId: undefined as undefined | number,
    isDocumented: false,
    isLoading: false,
    sihaDocumentation: new SiHaDocumentationResourceViewModel(),
    safetyHook: new SafetyHookResourceViewModel(),
    safetyAnchorPoint: new SafetyAnchorPointResourceViewModel(),
    safetyAnchorPointWasCreatedOrUpdated: false,
    safetyAnchorPointWasNotCreatedMissingData: false,
  }),
  computed: {
    documentationIncompleteHint() {
      if (!this.safetyAnchorPoint.isMinimalOnLocationDataSet) {
        return this.$t('page.mobileSafetyAnchorPointDetail.text.documentationIncompleteHint');
      }
      return undefined;
    },
    existsNextSafetyAnchorPoint() {
      return !!this.safetyAnchorPoint.nextSafetyAnchorPointId;
    },
    existsPreviousSafetyAnchorPoint() {
      return !!this.safetyAnchorPoint.previousSafetyAnchorPointId;
    },
    siHaDocumentationShareId() {
      return this.$router.currentRoute?.params?.shareId as string | undefined;
    },
    ifIsCreate(): boolean {
      return !this.safetyAnchorPointId;
    },
    safetyAnchorPointId() {
      const id = this.$router.currentRoute?.params?.id as string | undefined;
      if (id) {
        return Number.parseInt(id, 10);
      }
      return undefined;
    },
  },
  watch: {
    safetyAnchorPoint: {
      immediate: true,
      async handler() {
        this.downloadImages();
      },
    },
  },
  async mounted() {
    this.populateData(this.siHaDocumentationShareId!, this.safetyAnchorPointId!);
  },
  methods: {
    async downloadImages() {
      this.imagesLoading = true;
      this.safetyAnchorPointImageFiles = await this.$service.api.siHaSafetyAnchorPoint.downloadImageArrays(this.safetyAnchorPoint);
      this.imagesLoading = false;
    },
    async onAnchorPointImageInput(index: number | string, files: File[]) {
      const [id] = await this.$service.api.documents.uploadMultipleDocuments(files);
      (this.safetyAnchorPoint as any)[`documentationImage${index}Id`] = id;
    },
    async previousSafetyAnchorPoint() {
      if (this.existsPreviousSafetyAnchorPoint) {
        await this.createOrUpdateSafetyAnchorPoint();
        const prevAnchorId = this.safetyAnchorPoint.previousSafetyAnchorPointId;
        this.$router.push(`/glaromat/il/siha/mobile/documentation/${this.siHaDocumentationShareId}/safetyAnchorPoint/${prevAnchorId}`);
        await this.populateData(this.siHaDocumentationShareId!, prevAnchorId);
      }
    },
    async nextSafetyAnchorPoint() {
      if (this.existsNextSafetyAnchorPoint) {
        await this.createOrUpdateSafetyAnchorPoint();
        const nextAnchorId = this.safetyAnchorPoint.nextSafetyAnchorPointId;
        this.$router.push(`/glaromat/il/siha/mobile/documentation/${this.siHaDocumentationShareId}/safetyAnchorPoint/${nextAnchorId}`);
        await this.populateData(this.siHaDocumentationShareId!, nextAnchorId);
      }
    },
    async populateData(shareId: string, safetyAnchorPointId?: number | null) {
      this.isLoading = true;
      this.sihaDocumentation = await this.$service.api.siHaDocumentation.getSiHaDocumentationByShareId(shareId);
      this.siHaDocumentationId = this.sihaDocumentation.id;
      this.safetyAnchorPoint.siHaDocumentationId = this.siHaDocumentationId;
      const isCreate = !safetyAnchorPointId;
      if (isCreate) {
        this.setInstallationToToday();
      } else {
        const safetyAnchorPoint = await this.$service.api.siHaSafetyAnchorPoint.getSafetyAnchorPoint(safetyAnchorPointId);
        this.safetyHook = await this.$service.api.siHaSafetyHook.getSafetyHookById(safetyAnchorPoint.safetyHookId!);
        this.safetyAnchorPoint = safetyAnchorPoint;
      }
      this.isLoading = false;
    },
    setInstallationToToday() {
      this.safetyAnchorPoint.installationDate = this.$date.now().format('YYYY-MM-DD');
    },
    async loadSafetyHookInformation() {
      this.safetyHook = await this.$service.api.siHaSafetyHook.getSafetyHookById(this.safetyAnchorPoint.safetyHookId!);
    },
    async createOrUpdateSafetyAnchorPoint() {
      if (this.ifIsCreate) {
        if (!this.safetyAnchorPoint.safetyHookId) {
          this.safetyAnchorPointWasNotCreatedMissingData = true;
          return;
        }
        if (this.safetyAnchorPoint.name !== undefined && this.safetyAnchorPoint.name !== '') {
          // keep name
        } else {
          // create random name
          this.safetyAnchorPoint.name = new Date().getTime().toString();
        }
        await this.$service.api.siHaSafetyAnchorPoint.createSafetyAnchorPoint(this.safetyAnchorPoint);
      } else {
        await this.$service.api.siHaSafetyAnchorPoint.updateSafetyAnchorPoint(this.safetyAnchorPoint);
      }
      this.safetyAnchorPointWasCreatedOrUpdated = true;
      this.safetyAnchorPointWasNotCreatedMissingData = false;
      setTimeout(() => {
        this.safetyAnchorPointWasCreatedOrUpdated = false;
      }, 6000);
    },
  },
});
